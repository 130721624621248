// jQuery free
const API_ENDPOINT = '/api/shopfront/search/autocomplete';

let autocomplete = null,
    spinner = null,
    content = null,
    history = null,
    showAllAnchor = null;

export function setResultsVisibility(isAutocompleteActive = false) {
    if (!history) {
        history = document.getElementsByClassName('searchHistory')[0];
    }

    autocomplete.hidden = !isAutocompleteActive;
    history.hidden = isAutocompleteActive;
}

export function setSpinnerVisibility(isActive = false) {
    if (!spinner) {
        autocomplete.insertAdjacentHTML('afterbegin', `<div class='searchAcutocomplete__spinner'>${window.km_spinner.html}</div>`);
        spinner = autocomplete.getElementsByClassName('searchAcutocomplete__spinner')[0];
    }

    spinner.hidden = !isActive;
    content.hidden = isActive;
    showAllAnchor.hidden = isActive;
}


const eventKeys = {
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown'
};

function focusSibling(previous = false) {
    const element = document.activeElement;
    const sibling = previous ? element.previousElementSibling : element.nextElementSibling;

    if (sibling !== null) {
        sibling.focus();
    }
}

export default function searchAutocomplete(params) {
    const {searchForm, searchInput} = params;

    autocomplete = document.getElementsByClassName('searchAutocomplete')[0];
    if (!Boolean(Number(autocomplete.dataset.enabled))) {
        return;
    }

    let timeoutReference = null;
    let requestController = null;
    let lastTerm = null;


    const template = document.querySelector(`#searchAutocomplete__itemTemplate`);
    content = autocomplete.getElementsByClassName('searchAutocomplete__content')[0];

    showAllAnchor = content.lastElementChild;
    showAllAnchor.onmouseover = () => showAllAnchor.focus();

    searchInput.oninput = function (event) {
        const term = event.target.value.toString().trim();
        if (lastTerm === term) {
            return;
        }

        lastTerm = term;

        if (timeoutReference) {
            window.clearTimeout(timeoutReference);
        }

        if (requestController) {
            requestController.abort();
            requestController = null;
        }

        if (!term || term.length <= 1) {
            setResultsVisibility(false);
            setSpinnerVisibility(false);

            return;
        }

        timeoutReference = window.setTimeout(() => {
            requestController = new AbortController();
            setResultsVisibility(true);
            setSpinnerVisibility(true);

            while (content.firstElementChild && content.childElementCount > 1) {
                content.firstElementChild.remove();
            }

            fetch(API_ENDPOINT + `?term=${term}`, {
                method: 'GET',
                signal: requestController.signal,
                mode: 'same-origin', // send cookies _nss for isSameSite()
                headers: {
                    'X-Requested-With': 'XMLHttpRequest' // To force Nette isAjax() to be true.
                }
            }).then((response) => {
                response.json().then((data) => {
                    showAllAnchor.href = showAllAnchor.getAttribute('data-href').replace('__PLACEHOLDER__', encodeURIComponent(term));

                    for (const vehicle of data.vehicles) {
                        const {name, imageUrl, url, vin} = vehicle;
                        const clone = template.content.cloneNode(true);
                        const anchor = clone.querySelector('a');
                        const imageElement = anchor.getElementsByTagName('img')[0];
                        const headingElement = anchor.getElementsByClassName('searchAutocomplete__item__heading')[0];
                        const subHeadingElement = anchor.getElementsByClassName('searchAutocomplete__item__subHeading')[0];
                        anchor.href = url;
                        if (imageUrl) {
                            imageElement.src = imageUrl;
                            imageElement.alt = name;
                            imageElement.hidden = false;
                        }
                        if (vin) {
                            subHeadingElement.textContent = vin;
                            subHeadingElement.hidden = false;
                        }

                        anchor.onmouseover = () => anchor.focus();
                        headingElement.insertAdjacentText('beforeend', name);
                        content.insertBefore(anchor, showAllAnchor);
                    }

                    for (const category of data.categories) {
                        const {name, imageUrl, url, parentCategoryNames, productTypeName} = category;
                        const clone = template.content.cloneNode(true);

                        const anchor = clone.querySelector('a');
                        const imageElement = anchor.getElementsByTagName('img')[0];
                        const svgiconElement = anchor.querySelector('.searchAutocomplete__item__imageWrap .svgicon');
                        const headingElement = anchor.getElementsByClassName('searchAutocomplete__item__heading')[0];
                        const subHeadingElement = anchor.getElementsByClassName('searchAutocomplete__item__subHeading')[0];

                        anchor.href = url;
                        if (imageUrl) {
                            imageElement.src = imageUrl;
                            imageElement.hidden = false;
                            imageElement.alt = name;
                        } else if (imageElement.dataset.src) {
                            imageElement.src = imageElement.dataset.src;
                            imageElement.hidden = false;
                        } else {
                            svgiconElement.style.display = 'block';
                        }

                        headingElement.textContent = productTypeName ?? name;

                        parentCategoryNames.push(name);
                        subHeadingElement.textContent = parentCategoryNames.join(' → ');
                        subHeadingElement.hidden = false;

                        anchor.onmouseover = () => anchor.focus();

                        content.insertBefore(anchor, showAllAnchor);
                    }

                    for (const product of data.products) {
                        const {name, imageUrl, url, price, availabilityShortLabel, availabilityLabelColor, isAvailable, isAbleToOrder, stockStatus} = product;

                        const clone = template.content.cloneNode(true);

                        const anchor = clone.querySelector('a');
                        const imageElement = anchor.getElementsByTagName('img')[0];
                        const headingElement = anchor.getElementsByClassName('searchAutocomplete__item__heading')[0];
                        const availabilityLabelElement = anchor.getElementsByClassName('searchAutocomplete__item__availabilityLabel')[0];
                        const priceElement = anchor.getElementsByClassName('searchAutocomplete__item__price')[0];

                        anchor.href = url;
                        if (imageUrl) {
                            imageElement.src = imageUrl;
                            imageElement.alt = name;
                            imageElement.hidden = false;
                        }

                        anchor.onmouseover = () => anchor.focus();

                        headingElement.insertAdjacentText('beforeend', name);
                        availabilityLabelElement.insertAdjacentText('beforeend', availabilityShortLabel);
                        availabilityLabelElement.hidden = false;

                        if (isAbleToOrder) {
                            availabilityLabelElement.classList.add('--ableToOrder');
                        }
                        if (isAvailable) {
                            availabilityLabelElement.classList.add('--available');
                        }
                        if (stockStatus) {
                            availabilityLabelElement.classList.add('--' + stockStatus);
                        }
                        if (availabilityLabelColor) {
                            availabilityLabelElement.classList.add('--' + availabilityLabelColor);
                        }

                        if (price) {
                            priceElement.insertAdjacentText('beforeend', `${price.amountWithVat} ${price.currencyCode}`);
                        }
                        priceElement.hidden = false;

                        content.insertBefore(anchor, showAllAnchor);
                    }

                    setSpinnerVisibility(false);
                });
            }).catch((error) => {
                if (error.name === 'AbortError') {

                }
            });
        }, 500);
    };

    searchForm.onkeydown = (event) => {
        if (autocomplete.hidden === true || content.hidden === true) {
            return;
        }

        const {key} = event;
        const items = document.getElementsByClassName('searchAutocomplete__item');

        if (Object.values(eventKeys).includes(key)) {
            event.preventDefault();
        }

        switch (key) {
            case eventKeys.arrowDown: {
                if (searchInput === document.activeElement) {
                    items[0].focus();
                    break;
                }

                focusSibling();
                break;
            }

            case  eventKeys.arrowUp: {
                if (searchInput === document.activeElement) {
                    break;
                }

                if (document.activeElement.previousElementSibling === null) {
                    searchInput.focus();
                    break;
                }

                focusSibling(true);
                break;
            }
        }
    };
}