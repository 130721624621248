// jQuery free
import {onDOMReady, requestIdleCallback} from './dom';
import {decodeBlurHash} from 'fast-blurhash';

/**
 * @param {HTMLPictureElement} pictureElement
 */
function pictureInit(pictureElement) {
    if (pictureElement.dataset.initPicture || !pictureElement.id.startsWith('kvik_picture')) {
        return;
    }
    pictureElement.dataset.initPicture = '1';
    console.log('pictureInit', pictureElement);

    const imgElement = pictureElement.getElementsByTagName('img')[0];
    if (!imgElement) {
        return;
    }

    const isLoaded = imgElement.classList.contains('loaded');

    // reset inline styles set in ImageHtmlRenderer
    const unsetStyle = function () {
        imgElement.style.backgroundImage = 'none';
        imgElement.style.backgroundColor = 'inherit';
        imgElement.style.filter = 'none';
        imgElement.style.willChange = 'auto'; // https://blog.logrocket.com/when-how-use-css-will-change/
    };
    isLoaded ? requestIdleCallback(unsetStyle) : imgElement.addEventListener('load', () => requestIdleCallback(unsetStyle));

    if (isLoaded || !pictureElement.dataset.bh) {
        return;
    }

    // decode blurHash image
    const sizeInPx = 32;
    const pixels = decodeBlurHash(pictureElement.dataset.bh, sizeInPx, sizeInPx);

    // draw it on canvas
    const canvas = document.createElement('canvas');
    canvas.width = sizeInPx;
    canvas.height = sizeInPx;
    const context = canvas.getContext('2d');
    const imageData = context.createImageData(sizeInPx, sizeInPx);
    imageData.data.set(pixels);
    context.putImageData(imageData, 0, 0);

    // here is the most important part because if you don't replace you will get a DOM 18 exception.
    const dataUri = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    imgElement.style.backgroundImage = `url('${dataUri}')`;
    imgElement.style.backgroundSize = 'cover';
    imgElement.style.backgroundColor = 'inherit';
}

window.kvik_picture = pictureInit;

onDOMReady(function () {
    for (const pictureElement of document.getElementsByTagName('picture')) {
        pictureInit(pictureElement);
    }
}, false);